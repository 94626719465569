import React from 'react';
import logo from '../assets/logo.png';
import Intro from './intro/intro';
import axios from 'axios';
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Category from './category/category';
import Menu from './menu/menu';
import NotFound from './general/notfound';
import Loader from './general/loader';
import Feedback from './Feedback';

export default class Main extends React.Component {

    state = {
        settings: [],
        language: "krd",
        load: false,
    };

    async componentDidMount(){
        const {data} = await axios.get("https://manage.caviarmenu.net/api/info/rossel");
        this.setState({ settings: data }, ()=>{
            this.setState({load: true});
        });
    }

    handleLangaugeSubmit = (lang) => {
        this.setState({language : lang});
    }

    render(){
        return(
            <div className='wraper'>
                <div className='menu'>
                    { this.state.load 
                    ? 

                    <BrowserRouter>
                        <Routes>
                            <Route path="/feedback" element={<Feedback Settings={this.state.settings} Language={this.state.language} handleLangaugeSubmit = {this.handleLangaugeSubmit}></Feedback>} />
                            <Route path="/category" element={<Category Settings={this.state.settings} Language={this.state.language} handleLangaugeSubmit = {this.handleLangaugeSubmit} ></Category>} />
                            <Route path="/menu/:cat" element={<Menu Settings={this.state.settings} Language={this.state.language} handleLangaugeSubmit = {this.handleLangaugeSubmit} ></Menu>} />
                            <Route path="/home" exact element={ <Intro Settings={this.state.settings} Language={this.state.language} ItemId={this.state.ItemId} handleLangaugeSubmit = {this.handleLangaugeSubmit} ></Intro> } />
                            <Route path="/" element={<Navigate replace to="/home" />} />
                            <Route path="*" element={<NotFound></NotFound>} />

                            {/* <Navigate  from="/" to="/home" />
                            <Navigate  to="/notfound" /> */}
                        </Routes>
                    </BrowserRouter>

                    
                    : <Loader></Loader>
                }

                    <div className="footer">
                        Developed By <a href='https://www.instagram.com/caviar.menu/' className='caviar'>Caviar Menu</a>
                    </div>
                </div>
                <div className='main-logo'>
                    <img src={this.state.settings.logo} alt="" />
                </div>
            </div>
        );
    }

}
